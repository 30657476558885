import $ from "jquery";

$(window).on("load", () => {

	const sections = [
		[".ps-1", ".pst-1", ".psi-1", ".psl-1", ".psc-1", "ps-lg-1", "#115872"],
		[".ps-2", ".pst-2", ".psi-2", ".psl-2", ".psc-2", "ps-lg-2", "#146988"],
		[".ps-3", ".pst-3", ".psi-3", ".psl-3", ".psc-3", "ps-lg-3", "#146B8B"],
		[".ps-4", ".pst-4", ".psi-4", ".psl-4", ".psc-4", "ps-lg-4", "#146C8C"],
		[".ps-5", ".pst-5", ".psi-5", ".psl-5", ".psc-5", "ps-lg-5", "#146886"],
		[".ps-6", ".pst-6", ".psi-6", ".psl-6", ".psc-6", "ps-lg-6", "#125B75"],
		[".ps-7", ".pst-7", ".psi-7", ".psl-7", ".psc-7", "ps-lg-7", "#10526A"],
		[".ps-8", ".pst-8", ".psi-8", ".psl-8", ".psc-8", "ps-lg-8", "#10526A"]]

	const default_center_content = $("#default_center_content")
	const ps_wheel_section = $("#ps-wheel-section")
	let currentlySelected: null | [string, string, string, string, string, string, string] = null
	let def_bg = ps_wheel_section.attr("data-bg")

	let fadingAnimation: JQuery.Promise<JQuery<HTMLElement>> = null
	let fadingType: string = null
	let next_bg: string = null

	function fadeOutAndIn(){
		if ($("#ps-wheel-background").queue( "fx" ).length > 5){
			$("#ps-wheel-background").stop(true)
		}
		fadingAnimation = $("#ps-wheel-background").delay(50).fadeTo(300, 0, "linear").promise()
		fadingAnimation.done(() => {
			$("#ps-wheel-background").attr("src", next_bg)
			return
		}).then(() => {
			fadingAnimation = $("#ps-wheel-background").delay(50).fadeTo(300, 1, "linear").promise()
		})
	}

	function fading(new_bg: string) {
		next_bg = new_bg
		if (fadingAnimation == null) {
			fadeOutAndIn()
		}else {
			if (fadingType === "in"){
				fadeOutAndIn()
			}
		}
	}

	function changeCurrentlySelected() {
		if (currentlySelected !== null) {
			const currentSvg = $(currentlySelected[0])
			const currentTitle = $(currentlySelected[1])
			const currentLine = $(currentlySelected[3])
			const currentContent = $(currentlySelected[4])
			const currentLinearGradient = currentlySelected[5]
			const currentLineColor = currentlySelected[6]
			if (currentTitle) {
				currentTitle[0].classList.remove("font-bold", "bg-red", "text-white")
				currentTitle[0].classList.add("text_label_white")
			}
			if (currentSvg) {
				currentSvg.attr("fill", `url(#${currentLinearGradient})`)
			}
			if (currentLine) {
				currentLine.attr("stroke", currentLineColor)
			}
			if (currentContent && currentContent.length > 0) {
				currentContent[0].classList.add("hidden")
			}
		}
	}

	if (ps_wheel_section) {
		ps_wheel_section.on("mouseleave", () => {
			changeCurrentlySelected()
			default_center_content[0].classList.remove("hidden");
			if ($("#ps-wheel-background").attr("src") !== def_bg) {
				fading(def_bg)
			}
		})
	}

	sections.forEach((section) => {
			const svg = $(section[0])
			const title = $(section[1])
			const icon = $(section[2])
			const line = $(section[3])
			const content = $(section[4])

			const groupMouseOver = () => {

				if (currentlySelected == null || (currentlySelected !== null && currentlySelected[0] !== section[0])) {
					changeCurrentlySelected()
					if (title) {
						title[0].classList.remove("text_label_white")
						title[0].classList.add("font-bold", "bg-red", "text-white")
						let bg = $(title[0]).attr("data-bg")
						fading(bg)
					}
					if (svg) {
						svg.attr("fill", "url(#lingrad_red)")
					}
					if (line) {
						line.attr("stroke", "#AA003C")
					}
					if (content && content.length > 0) {
						content.get(0).classList.remove("hidden")
						default_center_content[0].classList.add("hidden")
					}
					//@ts-ignore
					currentlySelected = section
				}
			}
			if (svg) {
				svg.on("mouseover", groupMouseOver)
			}
			if (title) {
				title.on("mouseover", groupMouseOver)
			}
			if (icon) {
				icon.on("mouseover", groupMouseOver)
			}
			if (line) {
				line.on("mouseover", groupMouseOver)
			}
		}
	)
})


$(window).on("load", () => {
	let right_arrow_btn = $("#right_ps_mobile_btn")
	let left_arrow_btn = $("#left_ps_mobile_btn")
	let products_services = $("#mobile-ps");
	let clickScrollTimer: NodeJS.Timeout = null
	let currentSlide = 0
	let currentScrollTimeout = 0

	$(products_services).scrollLeft($(".mobile-ps-slide").get(currentSlide).offsetLeft)

	$(window).on("resize", () => {
		$(products_services).scrollLeft( $(".mobile-ps-slide").get(currentSlide).offsetLeft)
	})

	function disable_snap() {
		$(products_services.get(0)).removeClass("snap snap-x snap-mandatory")
		$(products_services.get(0)).children().each((idx, child) => {
			$(child).removeClass("snap snap-center")
		})
	}
	function enable_snap(timeout: number) {
		if (clickScrollTimer !== null){
			clearTimeout(clickScrollTimer);
			clickScrollTimer = null
		}

		clickScrollTimer = setTimeout(() => {
			$(products_services.get(0)).addClass("snap snap-x snap-mandatory")
			$(products_services.get(0)).children().each((idx, child) => {
				$(child).addClass("snap snap-center")
			})
		}, timeout)
	}

	right_arrow_btn.on("click", () => {
		if (currentSlide !== $('.milestone').length - 1){
			currentSlide += 1
		}
		disable_snap()
		let clientWidth = $(".mobile-ps-slide").get(0).clientWidth;
		currentScrollTimeout = 450
		products_services.animate({ scrollLeft: products_services.scrollLeft() + clientWidth}, 450);
		enable_snap(450)
	})

	left_arrow_btn.on("click", () => {
		if (currentSlide !== 0){
			currentSlide -= 1
		}
		disable_snap()
		let clientWidth = $(".mobile-ps-slide").get(0).clientWidth;
		currentScrollTimeout = 450
		products_services.animate({ scrollLeft: products_services.scrollLeft() - clientWidth}, 450);
		enable_snap(450)
	})
})
